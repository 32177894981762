import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export const formatCharacteristicData = (analysisData, contentType) => {
  const crowdedness =
    (_.get(analysisData, `${contentType}Crowdedness`) ||
      _.get(analysisData, 'crowdedness')) ??
    '0';
  const quality = _.get(analysisData, `${contentType}Quality`) ?? 'N/A';
  const memorability =
    _.get(analysisData, `${contentType}Memorability`) ?? 'N/A';
  const clarity =
    (_.get(analysisData, `VideoClarity`) ||
      _.get(analysisData, `videoClarity`) ||
      _.get(analysisData, `${contentType}Clarity`)) ??
    'N/A';

  return {
    crowdedness,
    quality,
    memorability,
    clarity,
  };
};

export const formatContentAnalysisData = ({ analysis, meta }) => {
  if (!analysis || _.isEmpty(analysis)) {
    return {};
  }
  const contentType = meta.dataFormat;
  analysis.mediaURL = meta.dataPath;
  analysis.contentType = contentType;
  analysis.key = uuidv4();

  if (contentType === 'image') {
    try {
      const {
        imageTopics,
        viralPotential,
        segmentData,
        imageFeatureInsights,
      } = analysis;

      const hashtagsString = (segmentData && segmentData[0]?.Hashtags) || '';
      const hashtags = hashtagsString.match(/#[a-zA-Z0-9_]+/g) || [];

      const interests = [];
      let caption;
      segmentData.forEach(segment => {
        const parsedInterests = JSON.parse(segment.audienceInterest);
        parsedInterests.forEach(interest => {
          if (!interests.includes(interest)) {
            interests.push(interest);
          }
        });

        const captionData = JSON.parse(segment.Caption);
        caption = captionData.Caption || captionData.caption;
      });

      return {
        ...analysis,
        topics: JSON.parse(imageTopics),
        hashtags,
        interests,
        caption,
        viralPotential,
        mediaInsight: imageFeatureInsights,
        ...formatCharacteristicData(analysis, 'image'),
      };
    } catch (e) {
      return {};
    }
  }

  if (contentType === 'video') {
    try {
      const {
        topics,
        videoInterests,
        videoHashtags,
        videoViralPotential,
        radSummary
      } = analysis;

      const hashtagsObject = videoHashtags;
      const hashtagsString = hashtagsObject.Hashtags || '';
      const hashtags = hashtagsString.match(/#[a-zA-Z0-9_]+/g) || [];

      return {
        ...analysis,
        topics,
        hashtags,
        viralPotential: videoViralPotential,
        interests: videoInterests,
        mediaInsight: radSummary,
        ...formatCharacteristicData(analysis, 'video'),
      };
    } catch (e) {
      return {};
    }
  }
};
