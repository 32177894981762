export const ROOT_URL = '/';
export const AUTO_LOGIN_URL = '/auto-login';

export const OPTIMIZE_PROFILE_URL = '/optimize/profile';
export const OPTIMIZE_CONTENT_URL = '/optimize/content';

export const ENGAGEMENT_WORDS_URL = '/engagement-words';

export const EMAILS_URL = '/emails';
export const EMAILS_CONTENT_URL = '/emails/content/:page?/';
export const EMAILS_PROFILES_URL = '/emails/profiles/:page?/';
export const EMAILS_PROFILES_CREATE_URL = '/emails/profile-create';
export const EMAILS_PROFILES_SINGLE_URL =
  '/emails/profile-view/:profileId?/:page?/';

export const HOME_URL = '/home';
export const CONTENT_URL = '/content';
export const BLOGS_URL = '/blogs';
export const PRODUCT_DESCRIPTIONS_URL = '/productDescriptions';

export const BLOGS_CONTENT_URL = '/blogs/content/:page?/';
export const BLOGS_PROFILES_URL = '/blogs/profiles/:page?/';
export const BLOGS_PERFORMANCE_URL = '/blogs/performance/';
export const BLOG_PROFILE_CREATE_URL = '/blogs/profile-create';
export const BLOG_PROFILE_SINGLE_URL =
  '/blogs/profile-view/:profileId?/:page?/';

export const ADS_URL = '/ads';
export const ADS_CONTENT_URL = '/ads/content';
export const ADS_PROFILES_URL = '/ads/profiles';
export const ADS_BATCHES_URL = '/ads/batches';
export const ADS_PROFILE_CREATE_URL = '/ads/profile-create';
export const ADS_PROFILE_SINGLE_URL = '/ads/profile-view/:profileId?/';

export const PRODUCT_DESCRIPTIONS_CONTENT_URL = '/productDescriptions/content';
export const PRODUCT_DESCRIPTIONS_PROFILES_URL =
  '/productDescriptions/profiles';
export const PRODUCT_DESCRIPTIONS_PROFILE_SINGLE_URL =
  '/productDescriptions/profile-view/:profileId?/';
export const PRODUCT_DESCRIPTIONS_PROFILE_CREATE_URL =
  '/productDescriptions/profile-create';

export const LANDING_PAGES_URL = '/landing-pages';
export const TOPICS_URL = '/topics';

export const CAMPAIGNS_URL = '/campaigns';
export const ADD_CAMPAIGN_URL = '/campaigns/create-campaign';
export const EDIT_CAMPAIGN_URL = '/campaigns/create-campaign/:campaignId?/';
export const CM_ROI_DASHBOARD_URL = '/campaigns/dashboard/:campaignId?/';
export const CM_ROI_DASHBOARD_URL_WITH_TAB_NAME = '/campaigns/dashboard/:campaignId?/:tabName?/:subTabName?';
export const CM_ROI_DASHBOARD_ADMIN_URL =
  '/campaigns/dashboard/:campaignId?/admin';
export const CM_ROI_DASHBOARD_PLAIN_URL = '/campaigns/dashboard/';
export const CM_SETTINGS_INTEGRATIONS_URL = '/campaigns/integrations/';
export const CM_DASHBOARD_SUMMARY = '/campaigns/summary/:campaignId?/';
export const CM_AUDIENCE_ADD_INTEREST =
  '/campaigns/:campaignId?/audiences/create/add-interest';
export const CM_AUDIENCE_ADD_FILTERS =
  '/campaigns/:campaignId?/audiences/create/add-interest/add-filters/:requestId?';
export const CM_PERSONA_DETAILS =
  '/campaigns/:campaignId?/audiences/details/:requestId?/:personaId?';
export const CM_PERSONA_DETAILS_STATE_MAP =
  '/campaigns/:campaignId?/audiences/details/:requestId?/:personaId?/state-map';
export const CM_INFLUENCER_LIST =
  '/campaigns/:campaignId?/audiences/details/:requestId?/:personaId?/influencers';
export const CM_INFLUENCER_LIST_DETAILS =
  '/campaigns/:campaignId?/audiences/details/:requestId?/:personaId?/influencers/:influencerId?/:reportId?';
export const CM_INFLUENCER_CONTENT_DETAILS =
  '/campaigns/:campaignId?/audiences/details/:requestId?/:personaId?/influencers/:influencerId?/:reportId?/post/:creatorPostId';
export const CM_INFLUENCER_COMPARE =
  '/campaigns/:campaignId?/audiences/details/:requestId?/:personaId?/influencers/compare/:compareArray?';
export const CM_PERSONA_COMPARE =
  '/campaigns/:campaignId?/audiences/compare/:personaId1?/:personaId2?/';
// Audience Engine URLs - DEPRECATED
export const PE_URL = '/persona';
export const PE_URL_CREATE = '/persona/create/';
export const PE_URL_ADD_INTEREST = '/persona/create/add-interest';
export const PE_URL_ADD_FILTERS =
  '/persona/create/add-interest/:campaignId?/add-filters/:requestId?';
export const PE_URL_ADD_INTEREST_FOR_CAMPAIGN =
  '/persona/create/add-interest/:campaignId?';
export const PE_URL_RESULTS = '/persona/results';
export const PE_URL_DETAILS = '/persona/create-persona/details';
export const PE_URL_DETAILS_ID = '/persona/details/:requestId?/:personaId?/';
export const PE_URL_DETAILS_V2_ID =
  '/persona/detailsV2/:requestId?/:personaId?/';
export const PE_URL_INFLUENCER_LIST_ID =
  '/persona/details/:requestId?/:personaId?/influencers/';
export const PE_URL_INFLUENCER_DETAILS_ID =
  '/persona/details/:requestId?/:personaId?/influencers/details/:influencerId?/:reportId?/';
export const PE_URL_INFLUENCER_DETAILS_V2_ID =
  '/persona/details/:requestId?/:personaId?/influencers/detailsv2/:influencerId?/:reportId?/';
export const PE_URL_INFLUENCER_COMPARE =
  '/persona/details/:requestId?/:personaId?/influencers/compare';
export const PE_URL_COMPARE = '/persona/compare/:personaId1?/:personaId2?/';
// Media Ranking Model
export const MRM_LIBRARY_URL = '/media/library/:requestId?/:segmentId?'; // segmentId = personaId
export const MRM_LIBRARY_DEFAULT_URL = '/media/library'; // universal persona
export const MRM_UPLOAD_URL = '/media/upload/:requestId?/:segmentId?'; // segmentId = personaId
export const MRM_UPLOAD_DEFAULT_URL = '/media/upload';
export const MRM_DETAILS_URL = '/media/details/:mediaId?';

export const LOGOUT_URL = '/logout';
export const SIGNUP_URL = '/signup';
export const SETTINGS_URL = '/settings';
export const SETTINGS_BILLING_URL = '/settings?to=billing';
export const SETTINGS_WORDLIST_URL = '/settings?to=wordlist';
export const SETTINGS_PROFILE_URL = '/settings?to=profile';
export const SETTINGS_INTEGRATIONS_URL = '/settings?to=integrations';
export const SETTINGS_BILLING_PLAN = '/settings?to=billing&focus=plan';
export const SETTINGS_BILLING_CANCEL = '/settings?to=billing&focus=downgrade';
export const SETTINGS_BILLING_TERMINATE =
  '/settings?to=billing&focus=closeAccount';

export const HELP_CENTER_URL = '//www.atomicreach.com/contact-support';
export const FORGOT_PASSWORD_URL = '/forgot-password';
export const RECOVER_PASSWORD_URL = '/recover-password';
export const EDITOR_URL = '/:contentType/editor/:contentId?/:integrationType?/';
export const EMPTY_EDITOR_URL = '/:contentType/editor/';
export const DEMO_EDITOR = '/demo/editor/';
export const ERROR404 = '/error/404';
export const ERROR500 = '/error/500';
export const ACCOUNT_UPGRADE = '/accountUpgrade';
export const ACCOUNT_EXPIRED = '/accountExpired';
export const CHARGIFY_CALLBACK = '/paymentCallback';

export const PRIVACY_POLICY_URL = 'https://www.atomicreach.com/privacy-policy';
export const TERMS_CONDITION_URL =
  'https://www.atomicreach.com/terms-and-conditions';
export const TRADEMARK_URL = 'https://www.atomicreach.com/trademark';

// === Urls for v2
export const ANALYZE_CONTENT_URL = '/analyze/content';
export const ANALYZE_PERFORMANCE_URL = '/analyze/performance';
export const ANALYZE_TRENDING_URL = '/analyze/trending';
export const ANALYZE_TOPICSINSIGHT_URL = '/analyze/topics-insight';
export const ANALYZE_TESTTABLE_URL = '/analyze/topics';
// === end Urls for v2

export const TIKTOK_OAUTH_URL = '/oauth/tiktok_h';
export const TIKTOK_OAUTH_URL_ADS = '/oauth/tiktok_ad';


export const HOMEPAGE_URL = CAMPAIGNS_URL;

export const OUTREACH_CONTENT_REVISION_DETAILS_URL = '/content-revisions/:hash?/revisionDetails/:revisionDetailsId?';
export const OUTREACH_CONTENT_REVISION_LIST_URL = '/content-revisions/:hash?';
export const REQUIRE_AUTH = [
  EMAILS_URL,
  HOME_URL,
  CONTENT_URL,
  BLOGS_URL,
  LANDING_PAGES_URL,
  TOPICS_URL,
  CAMPAIGNS_URL,
  EDITOR_URL,
  SETTINGS_URL,
  FORGOT_PASSWORD_URL,
  RECOVER_PASSWORD_URL,
  BLOGS_CONTENT_URL,
  EMAILS_CONTENT_URL,
  '/emails/editor',
  '/blogs/editor',
  ANALYZE_CONTENT_URL,
  ANALYZE_PERFORMANCE_URL,
];
