import _ from 'lodash';
import emptyContent from '../static/pics/emptyContent.png';

function formatYouTubeData(data) {
  return data.map(item => ({
    id: item.id,
    postId: item.post_id,
    userName: item.userName,
    description: item.description,
    thumbnail: item.thumbnail,
    videoUrl: item.video_url,
    genre: item.genre,
    channelId: item.channel_id,
    views: item.views,
    publishedAgo: item.published_ago,
    time: item.time,
    videoInfo: item.video_info
      ? JSON.parse(item.video_info.replace(/&quot;/g, '"'))
      : null,
    comments: item.comments
      ? JSON.parse(item.comments.replace(/&quot;/g, '"'))
      : null,
    respData: item.respData,
    createdAt: item.created_at,
    updatedAt: item.updated_at,
    mediaURLs: item.mediaURLs,
  }));
}

function formatTikTokData(data) {
  return data.map(item => ({
    id: item.id,
    postId: item.post_id,
    userName: item.userName,
    description: item.description,
    diggCount: parseInt(item.digg_count, 10),
    videoUrl: item.video_url,
    mediaInfo: item.media_info
      ? JSON.parse(item.media_info.replace(/&quot;/g, '"'))
      : null,
    playCount: parseInt(item.play_count, 10),
    shareCount: parseInt(item.share_count, 10),
    collectCount: parseInt(item.collect_count, 10),
    commentCount: parseInt(item.comment_count, 10),
    comments: item.comments,
    respData: item.respData,
    mediaURLs: item.mediaURLs,
  }));
}

function formatInstagramData(data) {
  return data.map(item => ({
    id: item.id,
    post_id: item.post_id,
    userName: item.userName,
    description: item.description,
    display_url: item.display_url,
    video_url: item.video_url,
    media_info: item.media_info,
    view_count: item.view_count,
    play_count: item.play_count,
    post_type: item.post_type,
    like_count: item.like_count,
    comment_count: item.comment_count,
    comments: item.comments,
    respData: item.respData,
    created_at: item.created_at,
    updated_at: item.updated_at,
    mediaURLs: item.mediaURLs,
  }));
}

// eslint-disable-next-line import/prefer-default-export
export function formatContentData(resp) {
  const socialNetwork = resp.creatorDetailsData[0]?.socialNetwork;
  switch (socialNetwork) {
    case 'youtube':
      return {
        status: resp.status,
        creatorDetailsData: resp.creatorDetailsData[0],
        data: formatYouTubeData(resp?.data?.data),
      };
    case 'tiktok':
      return {
        status: resp.status,
        creatorDetailsData: resp.creatorDetailsData[0],
        data: formatTikTokData(resp?.data?.data),
      };
    case 'instagram':
      return {
        status: resp.status,
        creatorDetailsData: resp.creatorDetailsData[0],
        data: formatInstagramData(resp?.data?.data),
      };
    default:
      return resp;
  }
}

const defaultImage = emptyContent;

export function getURLImage(socialNetwork, data) {
  switch (socialNetwork) {
    case 'youtube':
      return data?.thumbnail;
    case 'tiktok':
      return data?.respData?.video?.cover;
    case 'instagram':
      return data?.respData?.image_versions2?.candidates[0]?.url;
    default:
      return defaultImage;
  }
}

export function getContentFormattedData(socialNetwork, data) {
  switch (socialNetwork) {
    case 'youtube':
      return {
        impressions: parseInt(data.views, 10),
        likes: parseInt(data?.videoInfo?.short_info?.likes, 10),
        replies: parseInt(
          data?.comments?.comments_list?.comments?.length,
          0,
          10
        ),
        video_url: data.videoUrl,
        post_id: data.post_id,
        title_content: data?.respData?.title.replace(/[^a-zA-Z0-9\s!?]/g, ''),
        saves: null,
        published_date: data?.publishedAgo,
        shares: null,
        mediaURLs: JSON.parse(_.get(data, 'mediaURLs', '[]')),
        contentType: 'video',
      };
    case 'tiktok': {
      const publishedDate = new Date(data?.respData?.createTime * 1000);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedPublishedDate = publishedDate.toLocaleDateString(
        'en-US',
        options
      );
      return {
        impressions: parseInt(data.playCount, 10),
        likes: parseInt(data.diggCount, 10),
        replies: parseInt(data.commentCount, 10),
        video_url: data.videoUrl,
        post_id: data.post_id,
        title_content: null,
        saves: parseInt(data.collectCount, 10),
        published_date: formattedPublishedDate,
        shares: parseInt(data.shareCount, 10),
        mediaURLs: JSON.parse(_.get(data, 'mediaURLs', '[]')),
        contentType: 'video',
      };
    }
    case 'instagram': {
      const publishedDate = new Date(data?.respData?.taken_at * 1000);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const formattedPublishedDate = publishedDate.toLocaleDateString(
        'en-US',
        options
      );

      let contentType;
      switch (data.post_type) {
        case 'clips':
          contentType = 'video';
          break;
        case 'carousel_container':
          contentType = 'carousel';
          break;
        case 'feed':
        default:
          contentType = 'image';
      }

      return {
        impressions: parseInt(data.view_count, 10),
        likes: parseInt(data.like_count, 10),
        replies: parseInt(data.comment_count, 10),
        video_url: `https://www.instagram.com/${data.userName}/p/${data?.respData?.code}`,
        post_id: data.post_id,
        title_content: data?.description.replace(/[^a-zA-Z0-9\s!?]/g, ''),
        saves: null,
        published_date: formattedPublishedDate,
        shares: null,
        mediaURLs: JSON.parse(_.get(data, 'mediaURLs', '[]')),
        contentType,
      };
    }
    default:
      return {
        impressions: null,
        likes: null,
        replies: null,
        video_url: null,
        post_id: null,
        mediaURLs: [],
      };
  }
}
